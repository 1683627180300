import { useRuntimeConfig } from "nuxt/app"

export interface ScriptConfig {
  gtm?: string,
  gtmLyta?: boolean,
  gtag?: boolean,
  globalSite?: boolean,
  criteoItem?: { deviceSize: 'd' | 't' | 'm', item: string | null | undefined },
  criteoHome?: { deviceSize: 'd' | 't' | 'm' },
  gtag_page_view?: boolean,
  criteoCart?: boolean,
  criteoTag?: boolean,
  criteoScript?: boolean,
  criteoList?: string | boolean,
  remarketingTag?: boolean | KarteElement,
  dynamicFacebookAd?: KarteElement,
  facebookPixelCode?: string | boolean,
  facebookEventTag?: boolean,
  zendeskTag?: boolean,
  karteBlock?: boolean,
  karteSendEvent?: KarteElement,
  karteSendUser?: KarteElement,
  reproBoosterTag?: boolean
}

export interface KarteElement {
  email?: string,
  interested_course?: string,
  BriefingVideoURL?: string,
  name?: string,
  ad_item_id: string
  ad_item_price: string
}

export const scriptTag = ({
  gtm = useRuntimeConfig().public.GTM_ID, gtmLyta = false, gtag = true, globalSite = true,
  criteoItem = undefined,
  criteoHome = undefined, criteoScript = true, criteoList = false,
  remarketingTag = false, dynamicFacebookAd = undefined, facebookPixelCode = false,
  zendeskTag = true, karteBlock = true, karteSendEvent = undefined, karteSendUser = undefined,
  reproBoosterTag = useRuntimeConfig().public.REPRO_BOOSTER
}: ScriptConfig): any => {

  const GTM_AD_ID = useRuntimeConfig().public.GTM_AD_ID
  const GTM_LYTA_ID = useRuntimeConfig().public.GTM_LYTA_ID
  const KARTEBLOCK_KEY = useRuntimeConfig().public.KARTEBLOCK_KEY
  const ZENDESK_KEY = useRuntimeConfig().public.ZENDESK_KEY

  return {
    script: [
      ( gtm ? { hid: 'gtmHead', innerHTML: innerHTMLForGtmHead(gtm)} : { hid: 'gtmHead' } ),
      ( gtmLyta ? { hid: 'gtmLytaHead', innerHTML: innerHTMLForGtmHead(`${GTM_LYTA_ID}`) } : { hid: 'gtmLytaHead' }),
      ( gtag ? { hid: 'gtag', src: `https://www.googletagmanager.com/gtag/js?id=${GTM_AD_ID}`, async: true } : { hid: 'gtag' } ),
      ( globalSite ? { hid: 'globalSite', innerHTML: innerHTMLForGlobalSite() } : { hid: 'globalSite' }),
      ( criteoScript ? criteoScriptObject() : { hid: 'criteoScriptObject' }),
      ( criteoItem ? { hid: 'criteoItemTag', innerHTML: innerHTMLForCriteoItem(criteoItem.deviceSize!, criteoItem.item!), type: 'text/javascript'} : { hid: 'criteoItemTag' }),
      ( criteoHome ? { hid: 'criteoHome', innerHTML: innerHTMLForCriteoHome(criteoHome.deviceSize!) } : { hid: 'criteoHome' }),
      ( criteoList ? { hid: 'criteoList', innerHTML: innerHTMLForCriteoList(`${criteoList as string}`) } : { hid: 'criteoList' }),
      ( facebookPixelCode ? { hid: 'facebookPixelCode', innerHTML: innerHTMLForFacebookPixelCode(facebookPixelCode) } : { hid: 'facebookPixelCode' }),
      ( dynamicFacebookAd ? { hid: 'dynamicFacebookAd', innerHTML: innerHTMLForDynamicFacebookAd(dynamicFacebookAd) } : { hid: 'dynamicFacebookAd' }),
      ( zendeskTag ? { id: 'ze-snippet', src: `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`, async: true, type: 'text/javascript' } : { id: 'ze-snippet' }),
      ( remarketingTag ? { hid: 'remarketingTag', innerHTML: innerHTMLForRemarketingTag(remarketingTag) } : { hid: 'remarketingTag' }),
      ( karteBlock ? { hid: 'karteBlock', src: `https://cdn-blocks.karte.io/${KARTEBLOCK_KEY}/builder.js`} : {}),
      ( karteSendEvent ? { hid: 'karteSendEvent', innerHTML: innerHTMLForKarteSendEvent(karteSendEvent) } : { hid: 'karteSendEvent' } ),
      ( karteSendUser  ? { hid: 'karteSendUser', innerHTML: innerHTMLForKarteSendUser(karteSendUser) } : { hid: 'karteSendUser' }),
      ( reproBoosterTag ? { hid: 'reproBoosterTag', innerHTML: innerHTMLForReproBooster() } : { hid: 'reproBoosterTag' } )
    ],

    noscript: [
      (gtm ? { hid: 'gtmBody', innerHTML: innerHTMLForGtmBody(gtm), pbody: true } : { hid: 'gtmBody' }),
      (gtmLyta ? { hid: 'gtmLytaBody', innerHTML: innerHTMLForGtmBody(`${GTM_LYTA_ID}`), pbody: true } : { hid: 'gtmLytaBody', pbody: true }),
    ],

    __dangerouslyDisableSanitizersByTagID: {
    }
  }
}

const innerHTMLForGlobalSite = (): string => {
  const GTM_AD_ID = useRuntimeConfig().public.GTM_AD_ID
  const GTM_UA_ID = useRuntimeConfig().public.GTM_UA_ID

  return `
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "${GTM_AD_ID}", { 'allow_enhanced_conversions': true });
    gtag('config', "${GTM_UA_ID}")
  `
}

const innerHTMLForCriteoItem = (deviceSize: 'd' | 't' | 'm', itemName?: string): string => {
  const CRITEO_ACCOUNT_ID = useRuntimeConfig().public.CRITEO_ACCOUNT_ID

  return itemName && deviceSize ? `
    window.criteo_q = window.criteo_q || [];
    window.criteo_q.push(
      { event: "setAccount", account: "${CRITEO_ACCOUNT_ID}" },
      { event: "setSiteType", type: "${deviceSize}" },
      { event: "viewItem", item: "${itemName}" }
    );
  ` : ''
}

const criteoScriptObject = (): any => {
  return {
    hid: 'criteo',
    src: '//static.criteo.net/js/ld/ld.js',
    async: true,
    type: 'text/javascript',
  }
}

const innerHTMLForCriteoHome = (deviceSize: 'd' | 't' | 'm'): string | undefined => {
  const CRITEO_ACCOUNT_ID = useRuntimeConfig().public.CRITEO_ACCOUNT_ID

  if (deviceSize) {
    return `
      window.criteo_q = window.criteo_q || [];
      window.criteo_q.push(
      { event: "setAccount", account: "${CRITEO_ACCOUNT_ID}" },
      { event: "setSiteType", type: "${deviceSize}" },
      { event: "viewHome" }
      );
    `
  }
}

const innerHTMLForCriteoList = (deviceSize: 'd' | 't' | 'm' | string): string => {
  const CRITEO_ACCOUNT_ID = useRuntimeConfig().public.CRITEO_ACCOUNT_ID

  return `
    window.criteo_q = window.criteo_q || [];
    window.criteo_q.push(
      { event: "setAccount", account: "${CRITEO_ACCOUNT_ID}" },
      { event: "setSiteType", type: "${deviceSize}" },
      { event: "viewList", item: ["113", "100", "pack_25"] }
    );
  `
}

const innerHTMLForRemarketingTag = (element?: any): string | undefined => {
  const AD_ID = useRuntimeConfig().public.GOOGLE_REMARKETING_ID

  if (element && element.ad_item_id && element.ad_item_price) {
    return `
      gtag('event', 'page_view', {
        'send_to': '${AD_ID}',
        'value': '${element.ad_item_price}',
        'items': [{
          'id': '${element.ad_item_id}',
          'google_business_vertical': 'education'
        }]
      });
    `
  }
}

const innerHTMLForKarteSendEvent = (element?: KarteElement, host?: string): string | undefined => {
  const _host = host || useRuntimeConfig().public.STATIC_HOST

  if (element && element.email) {
    return `(function (params) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "karte_event",
        karte_event: "lead",
        karte_params: params
      })
    })({
      "email":"${element.email}",
      "subscription": true,
      "BriefingVideo_date": null,
      "InterestedCourse": "${element.interested_course}",
      "BriefingVideoURL": "${_host}${element.BriefingVideoURL}",
      "BriefingVideoDone_date":null,"FreeTrial_date":null,
      "FreeMentoring_date": null,
      "FreeMentoringReservation_date": null,
      "FreeCounseling_date": null,
      "FreeCounselingReservation_date": null,
      "Briefing_date": null,
      "NextNotice_date": null
    })
    `
  }
}


const innerHTMLForKarteSendUser = (element?: KarteElement): string | undefined => {
  if (element && element.email) {
    return `(function () {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        "user_id": null,
        "email": "${element.email}",
        "name": "${element.name}",
        "first_name":null,
        "last_name":null,
        "subscription":true
      })
    })()
    `
  }
}

export const innerHTMLForDynamicFacebookAd = (element?: KarteElement): string | undefined => {
  if (element && element.ad_item_id) {
    return `
      fbq('track', 'ViewContent', {
        contents: [{id: '${element.ad_item_id}', quantity: 1}],
        content_type: 'product'
      });
    `
  }
}

const innerHTMLForFacebookPixelCode = (facebookEventTag?: any) => {
  return `
    window.addEventListener('load', function() {
      ${facebookEventTag}
    })
  `
}

const innerHTMLForReproBooster = () => {
  return `
    (function() {
      var trackerName = null;
      var dimensionName = 'dimension11';
      !function(o,e,n){var r=[];if(window.reproio)console.info("Repro Web SDK was loaded more than once");else{window.reproio=function(){r.push(arguments)};var i=o.createElement(e),t=o.getElementsByTagName(e)[0];i.src="https://cdn.reproio.com/web/poc/common/repro-sdk.min.js",i.async=!0,i.crossOrigin="",i.onload=function(){window.reproio("setSnippetVersion","2.1"),r.forEach(function(o){window.reproio.apply(window.reproio,o)})},t.parentNode.insertBefore(i,t)}}(document,"script");
      reproio("setup", "530ab43b-4df5-4fbb-8d4d-4f7becc7102e", { precacher_ga_setting: { trackerName: trackerName, dimensionName: dimensionName } });
    })();
  `
}

const innerHTMLForGtmHead = (gtmId: string) => {
  return `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({
      'gtm.start':new Date().getTime(),event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;
      j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gtmId}');
  `
}

const innerHTMLForGtmBody = (gtmId: string) => {
  return `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
}
