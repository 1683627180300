import { scriptTag, innerHTMLForDynamicFacebookAd } from './scriptTag'
import type { KarteElement } from './scriptTag'
interface scriptTagsByConfig {
  env?: string,
  useCase: string,
  karteElement?: KarteElement,
  deviceSize?: 'd' | 't' | 'm',
  host?: string
}

interface scriptTags {
  script: Array<any>,
  noscript?: Array<any>,
  __dangerouslyDisableSanitizersByTagID: any
}

// 環境に応じた script tag の返答を期待する。
export const scriptTagsByConfig = ({ useCase, karteElement, deviceSize }: scriptTagsByConfig): scriptTags => {
  switch (useCase) {
    case 'common':
      return scriptTag({
        gtm: process.env.GTM_ID, gtag: true, globalSite: true, karteBlock: true
      })
    case 'commonBiz':
      return scriptTag({
        gtm: process.env.GTM_ID, gtag: true, globalSite: true, karteBlock: true, zendeskTag: false
      })
    case 'commonOptBiz':
      return scriptTag({
        gtag: true, globalSite: true,
        karteBlock: true
      })
    case 'index':
      return scriptTag({
        criteoScript: true, criteoHome: { deviceSize: deviceSize! },
        zendeskTag: true, karteBlock: true
      })
    case 'indexWithoutZendesk':
      return scriptTag({
        criteoScript: true, criteoHome: { deviceSize: deviceSize! },
        zendeskTag: false, karteBlock: true
      })
    case 'htmlcssTrial':
      return scriptTag({
        criteoScript: true,
        criteoHome: { deviceSize: deviceSize! },
        criteoItem: { deviceSize: deviceSize!, item: karteElement && karteElement.ad_item_id },
        zendeskTag: true,
        karteBlock: true
      })
    case 'briefing':
      return scriptTag({
        zendeskTag: true, criteoScript: true,
        criteoItem: { deviceSize: deviceSize!, item: karteElement && karteElement.ad_item_id },
        dynamicFacebookAd: karteElement, remarketingTag: karteElement,
        karteSendEvent: karteElement, karteSendUser: karteElement,
        karteBlock: true
      })
    case 'briefing-lp-s':
      return scriptTag({
        criteoScript: true, criteoItem: { deviceSize: deviceSize!, item: karteElement && karteElement.ad_item_id },
        gtag: true, zendeskTag: false,
      })
    case 'course':
      return scriptTag({
        criteoScript: true, criteoList: true,
        karteBlock: true
      })
    case 'set':
      return scriptTag({
        gtag: true, zendeskTag: false,
        remarketingTag: karteElement,
        criteoScript: true, criteoItem: { deviceSize: deviceSize!, item: karteElement && karteElement.ad_item_id },
        facebookPixelCode: innerHTMLForDynamicFacebookAd(karteElement),
      })
    case 'seminar':
      return scriptTag({
        criteoScript: true, criteoHome: { deviceSize: deviceSize! },
        zendeskTag: true, karteBlock: true
      })
    case 'yahoo':
      return scriptTag({
        gtmLyta: true,
        zendeskTag: false,
      })
    default: {
      return {
        script: [],
        noscript: [],
        __dangerouslyDisableSanitizersByTagID: []
      }
    }
  }
}
