import { defineAsyncComponent } from 'vue'
export default {
  "bu-default": defineAsyncComponent(() => import("/vercel/path0/layouts/bu/default.vue")),
  "bu-simple": defineAsyncComponent(() => import("/vercel/path0/layouts/bu/simple.vue")),
  default: defineAsyncComponent(() => import("/vercel/path0/layouts/default.vue")),
  "te-side-menu": defineAsyncComponent(() => import("/vercel/path0/layouts/te/side-menu.vue")),
  "te-simple-counseling": defineAsyncComponent(() => import("/vercel/path0/layouts/te/simple/counseling.vue")),
  "te-simple-engineer": defineAsyncComponent(() => import("/vercel/path0/layouts/te/simple/engineer.vue")),
  "te-simple-lp": defineAsyncComponent(() => import("/vercel/path0/layouts/te/simple/lp.vue")),
  "te-simple-marketer": defineAsyncComponent(() => import("/vercel/path0/layouts/te/simple/marketer.vue")),
  "te-simple-sidejob": defineAsyncComponent(() => import("/vercel/path0/layouts/te/simple/sidejob.vue")),
  "te-simple": defineAsyncComponent(() => import("/vercel/path0/layouts/te/simple/simple.vue")),
  "te-simple-webdesigner": defineAsyncComponent(() => import("/vercel/path0/layouts/te/simple/webdesigner.vue")),
  "yate-counseling": defineAsyncComponent(() => import("/vercel/path0/layouts/yate/counseling.vue")),
  "yate-side-menu": defineAsyncComponent(() => import("/vercel/path0/layouts/yate/side-menu.vue")),
  "yate-simple-nolink": defineAsyncComponent(() => import("/vercel/path0/layouts/yate/simple-nolink.vue")),
  "yate-simple": defineAsyncComponent(() => import("/vercel/path0/layouts/yate/simple.vue"))
}