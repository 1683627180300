import { dateToYesterday, daysOfWeek } from '~/utility/utility'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      yesterdayMonth(date: string): number {
        return dateToYesterday(new Date(date)).getMonth() + 1
      },
      yesterdayDays: (date: string): number => {
        return dateToYesterday(new Date(date)).getDate()
      },
      hours(date: string): number {
        return new Date(date).getHours() + 24
      },
      yesterdayDayOfWeek(date: string): '日' | '月' | '火' | '水' | '木' | '金' | '土' {
        const yesterday = dateToYesterday(new Date(date))
        const dayOfWeek = daysOfWeek(yesterday)
        return dayOfWeek
      },
      formatDayOfWeek(date: string): '日' | '月' | '火' | '水' | '木' | '金' | '土' {
        const dayOfWeek = daysOfWeek(new Date(date))
        return dayOfWeek
      },
      // 日付のフォーマットを変換する
      // 例
      // formatDate(new Date("2024-01-15Z00:00:00 "), 'ja-long') => '1月15日（月）'
      // formatDate(new Date("2024-01-15Z00:00:00 "), 'ja-short') => '1月15日（月）'
      // formatDate(new Date("2024-01-15Z00:00:00 "), '/') => '2024/1/15（月）'
      // formatDate(new Date("2024-01-15Z00:00:00 "), 'hoge') => '2024/1/15（月）'
      formatDate(date: Date, format: string) {
        switch (format) {
          case 'ja-long':
            return `${date.getMonth() + 1}月${date.getDate()}日（${daysOfWeek(date)}）`
          case 'ja-short':
            return `${date.getMonth() + 1}月${date.getDate()}日（${daysOfWeek(date)}）`
          case 'mm/dd':
            return `${date.getMonth() + 1}/${date.getDate()}`
          case '/':
            return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}（${daysOfWeek(date)}）`
        }
        return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}（${daysOfWeek(date)}）`
      },
      formatDateToMonthOfYesterday(date: Date | null) {
        if (!date) return '-'
        if (date.getDate() === 1) {
          const yesterday = new Date(date)
          yesterday.setDate(yesterday.getDate() - 1)
          return yesterday.getMonth() + 1
        }
        return date.getMonth() + 1
      },
      formatDateToYesterday(date: Date | null) {
        if (!date) return '-'
        if (date.getDate() === 1) {
          const yesterday = new Date(date)
          yesterday.setDate(yesterday.getDate() - 1)
          return yesterday.getDate()
        }
        return date.getDate() - 1
      },
      formatDateToHours(date: Date | null) {
        return date ? date.getHours() + 24 : '-'
      },
      formatDateToWeekOfYesterday(date: Date | null) {
        if (!date) return '-'
        const yesterday = new Date(date)
        yesterday.setDate(yesterday.getDate() - 1)
        const dayOfWeek = ['日', '月', '火', '水', '木', '金', '土'][
          yesterday.getDay()
        ]
        return dayOfWeek as '日' | '月' | '火' | '水' | '木' | '金' | '土'
      },
    }
  }
})
